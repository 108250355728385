import * as React from "react"
import '../../style.css'
import PPTCarousel from "../../components/carousel";
import Layout from "../../components/layout"

const BHT = () => {
    const info = {
        title:"Travel Inside the Black Hole at the Center of the Milky Way",
        folder:'/powerpoints/BHRA/Travel_Inside',
        folderLength:8,
        slides:null,
        ppt:'bh_eduguide_sec3.ppt',
        pptx:'bh_eduguide_sec3.pptx'
    }

    let slides=[]
    for(let i = 1; i <= info.folderLength; i++){
        slides.push(`${info.folder}/Slide${i}`);
    }

    return (
        <Layout>
                <h1>{info.title}</h1>
                <PPTCarousel slides={slides}/>
                <p>Download the slides as a <a href={`${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">ppt</a> or <a href={`${info.folder}/${info.pptx}`} download target='_blank' rel="noreferrer">pptx</a></p>
        </Layout>
  )
}

export default BHT